<template>
	<div class="tabList">
		<div @click="changeTab(item)" v-for="(item, index) in tabList" :key="index" class="tabList-item">
			<span class="tabList-item-title" :class="{'tabList-item-active': currentTab == index}">{{item.label}}</span>
			<transition :name="tranDirection">
				<span v-show="currentTab == index" class="tabList-item-line"></span>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			intTabList: {
				type: Array,
				default: function() {
					return []
				}
			}
		},
		data() {
			return {
				lastTab: null,
				currentTab: 0,
				tabList: [],
				tranDirection: "right"
			}
		},
		watch: {
			intTabList: {
				handler(val) {
					this.tabList = val
				},
				immediate: true
			}
		},
		methods: {
			changeTab(item) {
				if (item.value > this.currentTab) {
					this.tranDirection = "right"
				} else {
					this.tranDirection = "left"
				}
				this.currentTab = item.value
				this.$emit("updateJobList",item.name)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tabList {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #DCDFE6;

		.tabList-item {
			min-width: 56px;
			display: flex;
			flex-direction: column;
			margin-right: 20px;
			height: 30px;

			.tabList-item-title {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #303133;
				cursor: pointer;
				line-height: 20px;
				padding-bottom: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.tabList-item-line {
				height: 2px;
				background: #4392FA;
			}
		}

		.tabList-item-active {
			color: #4392FA !important;
		}
	}
</style>

<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="manhour">
		<div class="activity-data-tab" v-if="userType=='SCHOOL_MANAGER'">
			<tabList :intTabList="tabList" @updateJobList="updateJobList" />
			<span ></span>
		</div>
		<div class="manhour-data-list">
			<div class="data-list-search">
				<div class="list-search-row">
					<div class="list-search-col" v-if="userType=='SCHOOL_MANAGER'">
						<span class="list-search-label">所在学院：</span>
						<el-select :popper-append-to-body="false" class="list-search-select"
							v-model="searchForm.collegeId" placeholder="请选择">
							<el-option v-for="item in collegeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="list-search-col">
						<span class="list-search-label">岗位名称：</span>
						<el-input class="list-search-ipt" v-model="searchForm.postName" placeholder="请输入"></el-input>
					</div>
					<div class="list-search-col" v-if="userType=='COLLEGE_MANAGER'">
						<span class="list-search-label">岗位时间：</span>
						<el-date-picker class="list-search-date" v-model="date" value-format="yyyy-MM" type="monthrange"
							range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleChange($event)">
						</el-date-picker>
					</div>
					<div class="list-search-col">
						<span class="list-search-label">学号：</span>
						<el-input class="list-search-ipt" v-model="searchForm.studentNumber" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="list-search-row">
					<div class="list-search-col" v-if="userType=='SCHOOL_MANAGER'">
						<span class="list-search-label">岗位时间：</span>
						<el-date-picker class="list-search-date" v-model="date" value-format="yyyy-MM-dd" type="monthrange"
							range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleChange($event)">
						</el-date-picker>
					</div>
					<!-- <div class="list-search-col">
						<span class="list-search-label">岗状状态：</span>
						<el-select class="list-search-select" v-model="searchForm.postState" placeholder="请选择">
							<el-option label="已发布" value="ENABLE"></el-option>
							<el-option label="未发布" value="DISABLED"></el-option>
						</el-select>
					</div> -->
					<div class="list-search-col" >
						<span class="list-search-label">工时状态：</span>
						<el-select class="list-search-select" v-model="searchForm.workingHoursStatus" placeholder="请选择">
							<el-option v-for="(item,index) in workTimeList" :key="index" :label="item.name" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="list-search-col" v-if="postType=='S_FIX'">
						<span class="list-search-label">工时月份：</span>
						<el-date-picker class="list-search-date" v-model="dateMonth" value-format="yyyy-MM-dd" type="monthrange"
							range-separator="~" start-placeholder="开始月份" end-placeholder="结束月份" @change="handleChangeMonth($event)">
						</el-date-picker>
					</div>
					<div class="list-search-col" v-if="userType=='COLLEGE_MANAGER'" style="justify-content: space-between">
						<div style="display:flex">
							<span @click="search" class="list-search-confirm">查询</span>
							<span @click="reset" class="list-search-cancel">重置</span>
						</div>
						
						<div class="list-search-download" @click="exportWotkhours">
							<img class="search-download-icon" src="@/assets/images/pc/common/upload.png" alt="">
							<span class="search-download-text">导出数据</span>
						</div>
					</div>
				</div>
				<div class="list-search-row" style="flex:1;justify-content: space-between" v-if="userType=='SCHOOL_MANAGER'">
					<div class="list-search-col" style="justify-content: flex-start">
						<div class="list-search-download" @click="exportWotkhours">
							<img class="search-download-icon" src="@/assets/images/pc/common/upload.png" alt="">
							<span class="search-download-text">导出数据</span>
						</div>
					</div>
					<div class="list-search-col" style="justify-content: flex-end">
						<span @click="search" class="list-search-confirm">查询</span>
						<span @click="reset" class="list-search-cancel">重置</span>
						<!-- <div class="list-search-download" @click="exportWotkhours">
							<img class="search-download-icon" src="@/assets/images/pc/common/upload.png" alt="">
							<span class="search-download-text">导出数据</span>
						</div> -->
					</div>
				</div>
			</div>
			<div class="data-list-content">
				<el-table :data="tableData">
					<el-table-column  type="index" label="序号" width="80px">
					</el-table-column>
					<el-table-column prop="studentName" label="姓名">
					</el-table-column>
					<el-table-column prop="studentNumber" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院">
					</el-table-column>
					<el-table-column prop="stClass" label="班级">
					</el-table-column>
					<el-table-column prop="studentPhone" label="联系方式" width="120px">
					</el-table-column>
					<el-table-column prop="postName" label="岗位名称" width="120px">
						<template slot-scope="scope">
							<div  class="list-content-postName" @click="handleClick(scope)">
								<span class="content-action-postName">{{scope.row.postName}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="totalWorkingHour" label="总工时(h)" width="120px">
						<template slot-scope="scope">
							<div  class="list-content-action">
								<span class="content-action-hourfull">{{scope.row.totalWorkingHour}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="workingHour" label="当月工时" width="120px" v-if="(userType=='SCHOOL_MANAGER'&&postType=='S_FIX')||userType=='COLLEGE_MANAGER'">
						<template slot-scope="scope">
							<div  class="list-content-action">
								<span class="content-action-hourfull">{{scope.row.workingHour?scope.row.workingHour:0}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="workingHourTime" label="月份"  v-if="(userType=='SCHOOL_MANAGER'&&postType=='S_FIX')||userType=='COLLEGE_MANAGER'">
					</el-table-column>
					<el-table-column prop="publish" label="工时上传" v-if="userType=='COLLEGE_MANAGER'" width="120px">
						<template slot-scope="scope">
							<div class="list-content-action">
								<img class="select-icon" src="@/assets/images/pc/activity/select.png" alt="" v-if="scope.row.submit">
								<img class="select-icon pointor" src="@/assets/images/pc/activity/no-select.png" alt="" v-else @click="handleSelect('submit',scope.row)">
								<!-- <el-checkbox v-model="scope.row.confirm" /> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="publish" label="学生确认" v-if="userType=='COLLEGE_MANAGER'" width="120px">
						<template slot-scope="scope">
							
							<div class="list-content-action">
								<img class="select-icon" src="@/assets/images/pc/activity/select.png" alt="" v-if="scope.row.confirm">
								<img class="select-icon" src="@/assets/images/pc/activity/no-select.png" alt="" v-else>
								<!-- <el-checkbox v-model="scope.row.confirm" /> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="publish" label="已确认" v-if="userType=='SCHOOL_MANAGER'">
						<template slot-scope="scope">
							<div class="list-content-action">
								<img class="select-icon" src="@/assets/images/pc/activity/select.png" alt="" v-if="scope.row.confirm">
								<img class="select-icon" src="@/assets/images/pc/activity/no-select.png" alt="" v-else>
								<!-- <el-checkbox v-model="scope.row.confirm" /> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="publish" label="已审核" v-if="userType=='SCHOOL_MANAGER'">
						<template slot-scope="scope">
							
							<div class="list-content-action">
								<img class="select-icon" src="@/assets/images/pc/activity/select.png" alt="" v-if="scope.row.verify">
								<img class="select-icon pointor" src="@/assets/images/pc/activity/no-select.png" alt="" v-else @click="handleSelect('audit',scope.row)">
								<!-- <el-checkbox v-model="scope.row.verify" /> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="publish" label="已发放" v-if="userType=='SCHOOL_MANAGER'">
						<template slot-scope="scope">
							<div class="list-content-action">
								<img class="select-icon" src="@/assets/images/pc/activity/select.png" alt="" v-if="scope.row.settlement">
								<img class="select-icon pointor" src="@/assets/images/pc/activity/no-select.png" alt="" v-else @click="handleSelect('issue',scope.row)">
								<!-- <el-checkbox v-model="scope.row.settlement" /> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="common-pageNation-pc">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
		<div class="manhour-dialog">
			<el-dialog @close="closeManhourDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
				:visible.sync="manhourVisible">
				<div slot="title" class="dialog-header">
					<span>{{manhourTitle}}</span>
					<img @click="closeManhourDialog" src="@/assets/images/pc/common/close.png">
				</div>
				<el-form :model="addManhour" :rules="addManhourRules" ref="addManhour" class="">
					<el-form-item class="dialog-formitem" label="岗位工时:" prop="addManhour">
						<el-input type="text" class="dialog-formitem-ipt" v-model="addManhour.workingHour" placeholder="请输入该学生该月份获得的岗位工时">
						</el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer flex-item">
					<span @click="closeManhourDialog" class="cancel-btn">取 消</span>
					<span @click="submitManhourDialog" class="submit-btn">确 定</span>
				</span>
			</el-dialog>
		</div>

	</div>
</template>
<script>
	import storage from 'store'
	import {
		manhourListPc,
		workingHourVerify,
		workingHourSettlement,
		workingHourUpdate,
		workhoursExport
	} from "@/api/pc/manhour";
	import tabList from "../components/tabList.vue"
	import {
		collegeListPc,
	} from '@/api/pc/user'
	export default {
		components: {
			tabList
		},
		data() {
			return {
				tabList: [
					{
						label: "校内固定",
						value: "0",
						name:"S_FIX"
					},
					{
						label: "校内临时",
						value: "1",
						name:"S_TEMP"
					}
				],
				workTimeList:[],
				searchForm: {},
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				tableData: [],
				manhourVisible: false,
				manhourTitle: "工时上传",
				addManhour: {},
				addManhourRules: {
					num: [{
						required: true,
						message: "请输入该学生岗位工时",
						trigger: "change"
					}],
				},
				collegeList: [],
				userType:"",
				date: undefined,
				dateMonth:undefined,
				postType:""
			}
		},
		watch: {
			manhourVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["addManhour"].clearValidate()
						})
					}
				},
				deep: true
			}
		},
		mounted() {
			this.getCollegeList()
			this.userType = storage.get("accessInfo").userType
			if(this.userType=='SCHOOL_MANAGER'){
				this.postType='S_FIX'
				this.workTimeList = [
					{name:'学生未确认',value:'SUBMIT'},
					{name:'学生已确认',value:'CONFIRM'},
					{name:'勤工中心已审核',value:'VERIFY'},
					{name:'计财处已发放',value:'SETTLEMENT'},
				]
			}else{
				this.workTimeList = [
					{name:'学生未确认',value:'NO_SUBMIT'},
					{name:'工时已上传',value:'SUBMIT'},
					{name:'学生已确认',value:'CONFIRM'}
				]
			}
			this.getManhourList()
		},
		methods: {
			updateJobList(data){
				this.postType = data
				this.getManhourList()
			},
			//时间选择
			handleChange(val){
				console.log(val,'vallv')
				this.searchForm.startDay = val[0]
				this.searchForm.endDay = val[1]
			},
			//月份筛选
			handleChangeMonth(val){
				this.searchForm.startWorkingHourDay = val[0]
				this.searchForm.endWorkingHourDay = val[1]
			},
			// 获取学院列表
			getCollegeList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				collegeListPc(params).then((res) => {
					if (res.code == 0) {
						this.collegeList = res.data
					}
				})
			},
			//点击岗位名称跳转详情
			handleClick(scope){
				this.$router.push({
					path: "/pc/activity/jobDetail?id="+scope.row.postId,
				})
			},
			//工时导出
			exportWotkhours(){
				this.searchForm.postType = this.postType
				workhoursExport(this.searchForm).then((res) => {
					const blob = new Blob([res], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					})
					// console.log("downLoadActivity", URL.createObjectURL(blob))
					// window.location.href = URL.createObjectURL(blob)
					// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
					if ('download' in document.createElement('a')) {
						// 非IE下载
						const elink = document.createElement('a')
						elink.download = "工时列表.xlsx"
						elink.style.display = 'none'
						elink.href = URL.createObjectURL(blob)
						document.body.appendChild(elink)
						elink.click()
						URL.revokeObjectURL(elink.href) // 释放URL 对象
						document.body.removeChild(elink)
					} else {
						// IE10+下载
						navigator.msSaveBlob(blob, "岗位列表.xlsx")
					}
				})
			},
			//审核发放按钮
			handleSelect(type,row){
				switch(type){
					case "audit":
						this.$confirm('确定对当前岗位进行已审核操作吗？', '信息提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							workingHourVerify({workingHoursId:row.workingHoursId}).then(res=>{
								if(res.code==0){
									this.$message({
										type: 'success',
										message: '操作成功!'
									});
									this.getManhourList()
								}
							})
							
						}).catch(() => {});
						break;
					case "submit":
						this.manhourVisible=true
						// this.addManhour.id = row.id
						this.addManhour.workingHoursId = row.workingHoursId
						break;
					case "issue":
						this.$confirm('确定对当前岗位进行已发放操作吗？', '信息提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							workingHourSettlement({workingHoursId:row.workingHoursId}).then(res=>{
								if(res.code==0){
									this.$message({
										type: 'success',
										message: '操作成功!'
									});
									this.getManhourList()
								}
							})
							
						}).catch(() => {});
					break;
				}
			},
			// 获取工时列表
			getManhourList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					...this.searchForm
				}
				params.postType = this.postType
				console.log(this.searchForm,'search')
				console.log("params", params)
				this.tableData = [];
				manhourListPc(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			search() {
				this.pageIndex = 1;
				this.getManhourList();
			},
			reset() {
				this.pageIndex = 1;
				this.searchForm = {}
				this.date = ""
				this.dateMonth = ""
				this.searchForm.postType = this.postType
				this.getManhourList();
			},
			currentChange(val) {
				this.pageIndex = val;
				this.getManhourList();
			},
			upLoadManhour() {
				this.manhourVisible = true
			},
			closeManhourDialog() {
				this.manhourVisible = false
			},
			submitManhourDialog() {
				this.$refs["addManhour"].validate(valid => {
					if (valid) {
						workingHourUpdate(this.addManhour).then(res=>{
							if(res.code == 0){
								this.manhourVisible = false
								this.getManhourList()
							}
						})
					}
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	/deep/.list-search-col .el-input__inner {
		height: 32px;
		border-color: #DCDFE6;
		border-radius: 0px 2px 2px 0px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}
	/deep/.list-search-col .el-range-separator {
		line-height: 27px;
	}

	/deep/.list-search-col .el-input__icon.el-range__icon.el-icon-date {
		display: none;
	}

	/deep/.list-search-col .el-date-editor .el-range-input {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	/deep/.list-search-col .el-select .el-input .el-select__caret {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.data-list-content .el-table th.el-table__cell>.cell {
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
		line-height: 40px;
		background: #F7F8F9;
	}

	/deep/.data-list-content .el-table .el-table__cell {
		padding: 0 0;
	}

	/deep/.data-list-content .el-table .cell {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.el-dialog__header {
		padding: 0 24px !important;
		background: #F7F8F9;
		border-radius: 4px 4px 0px 0px;
	}

	/deep/.manhour-dialog .el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	/deep/.el-form-item__label {
		line-height: 36px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.submit-btn {
		width: 72px;
		height: 33px;
		background: #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.cancel-btn {
		width: 72px;
		height: 33px;
		border: 1px solid #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4392FA;
		margin-left: auto;
		margin-right: 16px;
		box-sizing: border-box;
	}

	.manhour {
		display: flex;
		flex-direction: column;

		.manhour-data-list {
			display: flex;
			flex-direction: column;

			.data-list-search {
				display: flex;
				flex-direction: column;

				.list-search-row {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-top: 8px;
					flex-wrap: wrap;
					.list-search-col:nth-child(3n){
						margin-right:0;
					}
					.list-search-col {
						width: 300px;
						display: flex;
						align-items: center;
						// padding-right: 10px;
						margin-right:20px;
						box-sizing: border-box;

						.list-search-label {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 32px;
							color: #909399;
						}

						.list-search-ipt {
							height: 32px;
							line-height: 32px;
							flex: 1;
						}

						.list-search-date {
							flex: 1;
							height: 32px;
						}

						.list-search-select {
							flex: 1;
							height: 32px;
						}

						.list-search-confirm {
							width: 68px;
							line-height: 32px;
							background: #4392FA;
							border-radius: 2px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #FFFFFF;
							margin-right: 8px;
							cursor: pointer;
						}

						.list-search-cancel {
							width: 68px;
							line-height: 32px;
							border: 1px solid #4392FA;
							border-radius: 2px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #4392FA;
							cursor: pointer;
						}

						.list-search-download {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 90px;
							height: 32px;
							border: 1px solid #DCDFE6;
							border-radius: 2px;
							// margin-left: auto;
							cursor: pointer;

							.search-download-icon {
								width: 14px;
								height: 14px;
								margin-right: 4px;
							}

							.search-download-text {
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 400;
								color: #606266;
							}
						}
					}
				}
			}
			.list-content-postName{
				color:#4392FA;
				text-decoration:underline;
				cursor: pointer;
			}
			.data-list-content {
				border: 1px solid #DCDFE6;
				border-radius: 2px;
				margin-top: 16px;

				.list-content-action {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 48px;
					.select-icon{
						width:16px;
						height:16px;
					}
					.pointor{
						cursor: pointer;
					}
					.content-action-hourfull {
						min-width: 30px;
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #303133;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}

		.dialog-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 50px;

			span {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 50px;
				color: #303133;
				display: flex;
				align-items: center;
			}

			img {
				width: 15px;
				height: 15px;
				cursor: pointer;
			}
		}

		.dialog-formitem {
			width: 100%;
			display: flex;
			align-items: center;

			.dialog-formitem-ipt {
				flex: 1;
				border: 1px solid #DCDFE6;
				margin-right: 18px;
				min-width: 350px;
			}
		}
	}
</style>
